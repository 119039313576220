import React from "react";
import Form from "./Form";

function Contact() {
  return (
    <div style={{ marginBottom: 30 }} className="Form-wrapper">
      <div className="z-depth-0">
        <div className="row">
          <div className="col s12 ">
            <h2 className="white-text darken-5">Contact us</h2>
          </div>
          <div className="col s12 l7 ">
            <div>
              <blockquote className="z-depth-2">
                <h3 className="indigo-text text-darken-4">
                  Registered Address{" "}
                </h3>
                <p>
                  <span>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                    Location: -
                  </span>{" "}
                  <span>
                    No- 11 Sujanna Nagar, N.B Factory Road, Kaval Byrasandra,
                    <br /> R T Nagar Post, Bengaluru, Karnataka - 560032
                  </span>
                </p>
                <p>
                  <span>
                    <i class="fa fa-envelope" aria-hidden="true"></i> Email: -
                  </span>{" "}
                  <span>supportus@lifebeginsindia.in</span>
                </p>
                <p>
                  <span>
                    <i class="fa fa-phone" aria-hidden="true"></i> phone no: -
                  </span>{" "}
                  <span>+91-8431221686 / +91-8147279877</span>
                </p>
              </blockquote>
            </div>
            <div>
              <blockquote className="z-depth-2">
                <h3 className="indigo-text text-darken-4">Office Address </h3>
                <p>
                  <span>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                    Location: -
                  </span>{" "}
                  <span>
                    #42, 2nd Cross, Shampura Main Road, Munivearappa Layout,
                    <br /> Bengaluru, Karanataka - 560032{" "}
                  </span>
                </p>
                <p>
                  <span>
                    <i class="fa fa-envelope" aria-hidden="true"></i> Email: -
                  </span>{" "}
                  <span>supportus@lifebeginsindia.in</span>
                </p>
                <p>
                  <span>
                    <i class="fa fa-phone" aria-hidden="true"></i> phone no: -
                  </span>{" "}
                  <span>+91-8431221686 / +91-8147279877</span>
                </p>
              </blockquote>
            </div>
            <div>
              <blockquote className="z-depth-2">
                <h3 className="indigo-text text-darken-4  ">
                  Our Account Details
                </h3>

                <div className="row">
                  <div className="col  l6 ">
                    <div style={{ marginTop: 20 }}>
                      <p>
                        <span>Account name :</span> <span>Life begins</span>
                      </p>
                      <p>
                        <span>A/c number : </span> <span>920020071578063</span>
                      </p>
                      <p>
                        <span>IFSC :</span> <span>UTIB0000363 </span>
                      </p>
                      <p>
                        <span>Branch :</span> <span>R T Nagar</span>
                      </p>
                    </div>
                  </div>
                  <div className="col  l4 offsetl-2">
                    <img src={require("assets/Images/QR.jpg")} alt="..." />
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
          <div style={{ marginRight: -30 }} className="col l4 offset-l1   ">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
