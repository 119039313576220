import React from "react";

function Horizontal({ text }) {
  return (
    <div className="indigo darken-3 wrapper section">
      <div className="container">
        <div className="row">
          <div className="col  s12 l12">
            <h4
              style={{ fontSize: 30, fontWeight: 600 }}
              className="white-text "
            >
              {text}
            </h4>
            <hr className="red" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Horizontal;
