import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function Values() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our Core Values</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={require("assets/Images/Integrity.jpg")}
              alt="..."
              width="150"
              height="150"
            />
            <h3 style={{ color: "#283593" }}>Integrity</h3>
            <p style={{ color: "#000000" }}>
              When faced with difficult decisions and hard choices, we do the
              right thing, even in the face of adversity. Maintaining social,
              ethical and organisational norms and adhering to the code of
              conduct.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={require("assets/Images/transparent.jpg")}
              alt="..."
              width="150"
              height="150"
            />
            <h3 style={{ color: "#283593" }}>Transparency</h3>
            <p style={{ color: "#000000" }}>
              Life Begin Foundation is a registered non-profit which complies
              with all applicable statutory and regulatory requirements. Our
              financials are available on our website and we are audited
              annually by an independent auditor.{" "}
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={require("assets/Images/Believe.jpg")}
              alt="..."
              width="150"
              height="150"
            />
            <h3 style={{ color: "#283593" }}>Believe its Possible</h3>
            <p style={{ color: "#000000" }}>
              Life Begins Foundation implements monitoring and evaluation tools
              for each of its projects to ensure optimal utilization of funds
              and maximize impact.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={require("assets/Images/team.jpg")}
              alt="..."
              width="150"
              height="150"
            />
            <h3 style={{ color: "#283593" }}>Team worK</h3>
            <p style={{ color: "#000000" }}>
              Life Begins is a not-for-profit organization working in Bangalore
              India, focusing on alleviating poverty and social injustice. We do
              this through well planned and comprehensive projects in health,
              education, livelihoods and disaster preparedness and response. The
              Board consists of a group of highly accomplished professionals who
              share a common passion for empowering people to alleviate poverty
              and reduce social exclusion.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={require("assets/Images/passion.jpg")}
              alt="..."
              width="150"
              height="150"
            />
            <h3 style={{ color: "#283593" }}>Passion</h3>
            <p style={{ color: "#000000" }}>
              We have a passion for Helping Peoples to improve their way of
              living.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
