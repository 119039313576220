import React from "react";
//import Card from "./Card";
//import TestimonialCard from "./TestimonialCard";

//css
//import "./Css/About.css";

function Blogs() {
  return (
    <div className="lb_about_wrapper white">
      <h2 className="center">Our Blogs</h2>
      <div className="container  ">
        <div className="row">
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img src={require("assets/Images/blog1.jpg")} alt="..." />
              </div>

              <div className="card-content">
                <span className="card-title">
                  “We want to get every Indian volunteering”
                </span>
                <p>
                  Says Simeon V founder & CEO Life Begins – and what he did
                  during the pandemic pause STARTED by a small group of friends
                  in 2020, Life begins provides the youth with a platform to
                  serve the society and bridges the gap between the educated and
                  the uneducated. We are one of known name in non-profit
                  organizations for youth volunteering. It enables a pool of
                  about 100 volunteers who educate and mentor children from
                  orphanages, slum and village community centres across the
                  Bangalore to give them a better future. The learning
                  experience is mutual – volunteers gain perspective and the
                  experience to mould themselves into tomorrow’s leaders.
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img src={require("assets/Images/blog2.jpg")} alt="..." />
              </div>

              <div className="card-content">
                <span className="card-title">
                  reasons why vaccinations are important for our health
                </span>
                <p>
                  As inoculation programmes against COVID-19 begin, a checklist
                  for better understanding THROUGHOUT our lives, we are exposed
                  to a number of viruses, bacteria and other microbes. Some of
                  these microbes benefit us while others cause diseases that
                  could result in severe illness, disability or even death. Our
                  body’s immune system protects us against several infections by
                  triggering reactions that neutralize harmful microbes and
                  prevent the infection. But it is unable to fight certain
                  diseases like the novel Coronavirus. That is where
                  vaccinations play a hugely important role, as in they help the
                  immune system to create antibodies that protect the body from
                  diseases.
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img src={require("assets/Images/blog3.png")} alt="..." />
              </div>

              <div className="card-content">
                <span className="card-title">
                  Life Begins Fundraisers just got better
                </span>
                <p>
                  HERE is some exciting news – the fundraising pages on
                  GiveIndia Fundraisers have just been given a facelift, a
                  makeover that will give you an enhanced user experience when
                  raising funds on the platform. As this new design interface
                  goes live at 10am on January 30, 2021 it is the start of a new
                  dawn for our crowdfunding platform, with many new features in
                  the pipeline for our fundraiser pages. One of the key reasons
                  for this is providing all of you with a much better, more
                  satisfying user experience. All fundraisers that were created
                  before the changeover to the new-look pages will continue to
                  have the old design till February 20, 2021. However, if you
                  make any changes to your existing fundraiser before this date,
                  it will automatically migrate to the new design. Fundraisers
                  set up after 10am on January 30 will benefit from the spanking
                  new design.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
