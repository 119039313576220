import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function Livelihood() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Lets talk about Livelihood</h2>
          {/*<h5 className={classes.description}>
            This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more.
  </h5>*/}
        </GridItem>
      </GridContainer>
      <div>
        <div className="row">
          <div className="col">
            <p>
              One in eight of the world’s population lives in an Indian village;
              India’s 600,000 villages contain more of the world’s people living
              Below the Poverty Line than the whole population of Brazil, as
              well as one third of the world’s malnourished children.
            </p>
            <p>
              To Address the Livelihood Challenges and marginalized people in
              Backward communities and to bring positive changes in their lives,
              We help people in Livelihood improvement, ensuring food nutrition
              at the household of community level.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
