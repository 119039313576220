import React from "react";
//import Card from "./Card";
//import TestimonialCard from "./TestimonialCard";

//css
//import "./Css/About.css";

function Testimonial() {
  return (
    <div className="lb_about_wrapper white">
      <h2 className="center">SpotLight</h2>
      <div className="container  ">
        <div className="row">
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img
                  src={require("assets/Images/DonorPic/pic1.jpg")}
                  alt="..."
                />
              </div>

              <div className="card-content">
                <span className="card-title">
                  Clifford Fernando & son carlin{" "}
                </span>
                <p>
                  Thanking you and your family for sponsoring this website. your
                  generosity will not go unappropriate, team life begins
                  privileged and humbled to receive this support for you & your
                  family, we would like to express our heartfelt thanks once
                  again for choosing us...!!!
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img src={require("assets/Images/DonorPic/02.jpg")} alt="..." />
              </div>

              <div className="card-content">
                <span className="card-title">kavitha reddy </span>
                <p>
                  This is a necessary step towards a sustainable world. im happy
                  to see youngsters taking action towards the society. i am
                  proud to be a part it.
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 l4">
            <div className="card z-depth-0">
              <div className="card-image">
                <img src={require("assets/Images/DonorPic/01.jpg")} alt="..." />
              </div>

              <div className="card-content">
                <span className="card-title">Ayushi kumari </span>
                <p>
                  Working with Life begins has been a very revealing and
                  enriching experience I realized that there are many small
                  things that can be done to contribute to child education. Yes,
                  what is needed is a bit of sensitivity and willingness to do
                  something worthwhile.
                </p>
              </div>
            </div>
          </div>
          {/*<div className="col s12 l3">
            <div className="lb_cardbtn_wrapper">
          <a
            href="/#"
            target="_blank"
            class="waves-effect waves-light btn indigo"
          >
          Button to open other Images in Lightbox
          </a>
        </div>
  </div>*/}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
